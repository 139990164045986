<template>
  <div class="invoices root">
    <div v-if="disablePage" class="text-center pt-4">
      Coming soon...
    </div>
    <div v-if="!disablePage" class="listData px-3">
      <div
        v-if="saleType === 'wholesale' || saleType === 'exclusivity'"
        class="wholesale-calendar-wrapper pt-4"
      >
        <!-- <div class="row score-container p-2">
          <div class="col-7">คะแนนที่ได้รับทั้งหมด</div>
          <div class="col-5 text-right">{{ $numberWithCommas(total) }} P</div>
          <div class="col-7">คะแนนที่ใช้ไปทั้งหมด</div>
          <div class="col-5 text-right">{{ $numberWithCommas(used) }} P</div>
          <div class="col-7"><b>คะแนนคงเหลือ</b></div>
          <div class="col-5 text-right">{{ $numberWithCommas(remain) }} P</div>
          <div class="col-7 hilight"><b>คะแนนรอผ่านเป้าคงเหลือ</b></div>
          <div class="col-5 hilight text-right">{{ $numberWithCommas(pending) }} P</div>
        </div>
        <hr class="row mt-0" />
        <WholesaleCalendar :dataList="scoreList" v-if="scoreList.length > 0" />
        <div class="remark-container">
         
            หมายเหตุ: 
            <br/>
            • ต้องมียอดซื้อสะสมถึง 85% ของเป้าหมายสะสมถึงสามารถใช้คะแนนแลกของรางวัลได้
            <br/>
            • ช่องทางร้านค้าส่งที่มีเป้าหมายการสั่งซื้อ ที่มีคะแนนรอผ่านเป้า สามารถใช้คะแนนรอผ่านเป้าในการแลกสิทธิ์ร่วมรายการ Lucky Surprise 100 คะแนน = 1 สิทธิ์
            <br/>
            • ร้านค้าที่มีคะแนนผ่านเป้า (คะแนนด้านบนสุด) ให้ใช้คะแนนในการแลกของรางวัล หรือ แลกสิทธิ์ร่วมรายการ Lucky Surprise ก่อน จึงจะสามารถใช้คะแนนรอผ่านเป้าได้
            <br/>
            • คะแนนรอผ่านเป้าขอสงวนสิทธิ์ใช้ได้เฉพาะการแลกสิทธิ์ร่วมรายการ Lucky Surprise เท่านั้น
        </div> -->
        <WholesaleCalendar
          :dataList="targetList"
          v-if="targetList.length > 0"
        />
        <!-- <hr class="row" v-if="scoreList.length > 0" /> -->
        <b>ประวัติคะแนน</b>
      </div>
      <div>
        <div v-for="(it, index) in data_invoices" :key="index" class="row item">
          <div class="col des p-1">
            <div class>
              <b>วันที่:</b>
              {{ dateTolocal(it.transactionDate) }}
            </div>
            <div class>
              <b>เลขที่ใบเสร็จ</b>
              {{ $invoToString(it.receiptNo) }}
            </div>
          </div>
          <div class="col-3 p-1">
            <div v-if="buttonVisible" :class="'box_status ' + it.status">
              <div class="box_text">
                <div class="text">
                  <div
                    v-if="it.status == 'uncollect'"
                    v-on:click="
                      confirmClick(
                        dateTolocal(it.transactionDate),
                        it.id,
                        it.receiptNo
                      )
                    "
                  >
                    กดยืนยัน
                    <br />รับคะแนน
                  </div>
                  <div v-if="it.status == 'collected'">+{{ it.point }}</div>
                  <div v-if="it.status == 'pending'">
                    รอการ
                    <br />ตรวจสอบ
                  </div>
                  <div
                    v-if="it.status == 'incomplete'"
                    v-on:click="incompleteAlert()"
                  >
                    รายการ
                    <br />ไม่สมบูรณ์
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <infinite-loading
          v-if="infinShow"
          @infinite="getInvoice"
          ref="infiniteLoading"
          spinner="waveDots"
          force-use-infinite-wrapper
        >
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </div>
      <div v-if="dataStage == 'loading'">
        <vue-content-loading
          v-for="i in 6"
          :key="i + '_'"
          :weight="100"
          :height="130"
        >
          <rect :y="45" rx="4" ry="4" width="40%" height="15" />
          <rect y="85" rx="4" ry="4" width="60%" height="15" />
          <rect y="25" x="77%" rx="15" ry="15" width="23%" height="69%" />
        </vue-content-loading>
      </div>
      <div v-if="dataStage == 'error'" class="text-center pt-4">
        Coming soon!
        <!-- มีข้อผิดพลาด กรุณาลองอีกครั้ง หรือติดต่อเจ้าหน้าที่ -->
      </div>
      <div v-if="dataStage == 'nodata'" class="text-center pt-4">
        Coming soon
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { forEach, size } from "lodash";
import VueContentLoading from "vue-content-loading";
import InfiniteLoading from "vue-infinite-loading";
import WholesaleCalendar from "@/components/wholesaleCalendar";

export default {
  name: "invoices",
  components: {
    VueContentLoading,
    InfiniteLoading,
    WholesaleCalendar,
  },
  computed: {
    saleType() {
      const { user_data } = this.$store.state;
      const customerSaleType = user_data?.customerSaleType;
      const shopCode = user_data?.shopCode;
      if (customerSaleType?.toLowerCase() === "wholesale") {
        this.getPoint(shopCode);
      }
      return customerSaleType ? customerSaleType.toLowerCase() : "";
    },
    buttonVisible() {
      // const { user_data } = this.$store.state;
      return new Date() > new Date(2023, 10, 21);
    },
    // scoreList() {
    //   const { dataList } = this;
    //   return dataList;
    // },
    targetList() {
      const { dataList } = this;
      const { wholesaleTarget } = this.$store.state;
      let newArray = [];
      if (size(dataList) > size(wholesaleTarget)) {
        forEach(dataList, (i, index) => {
          newArray.push({
            ...dataList[index],
            ...wholesaleTarget.find(
              (j) =>
                j.month === dataList[index].month &&
                j.year === dataList[index].year
            ),
          });
        });
      } else {
        forEach(wholesaleTarget, (i, index) => {
          newArray.push({
            ...wholesaleTarget[index],
            ...dataList.find(
              (j) =>
                j.month === wholesaleTarget[index].month &&
                j.year === wholesaleTarget[index].year
            ),
          });
        });
      }
      const result = this.generateList(newArray) || [];
      return result;
      // return wholesaleTarget?.length > 0 ? wholesaleTarget : [];
    },
  },
  data() {
    return {
      dataStage: "loading",
      // data_invoices: [
      //   {
      //     id: 1,
      //     refOrderInfo: "4345611222388xxxx",
      //     transactionDate: "2019-12-03 00:00:00",
      //     point: 100.0,
      //     status: "incomplete",
      //     createdAt: "2019-12-03 00:00:00",
      //     updatedAt: "2019-12-03 00:00:00"
      //   }
      // ],
      next_page_url: `${process.env.VUE_APP_API_URL}/invoices?page=1`,
      data_invoices: [],
      infinShow: false,
      total: 0,
      used: 0,
      remain: 0,
      pending: 0,
      dataList: [],
      disablePage: false,
    };
  },
  methods: {
    async fetchInvoices() {
      // get shop info
      return await fetch(this.next_page_url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.$JWT}`,
        },
      });
    },
    async getInvoice() {
      try {
        const res = await this.fetchInvoices();
        console.log("fetchInvoices status:", res.status);
        let body = [];
        switch (res.status) {
          case 200: {
            this.dataStage = "already";
            body = await res.json();
            this.next_page_url = body.nextPageUrl;
            this.data_invoices = this.data_invoices.concat(body.data);
            if (this.data_invoices.length == 0) {
              this.dataStage = "nodata";
              break;
            }
            if (body.currentPage >= body.lastPage) {
              this.infinShow = false;
            } else {
              this.infinShow = true;
            }
            break;
          }
          case 401: {
            this.$Error401();
            throw "401";
          }
          default: {
            throw "no case";
          }
        }
      } catch (error) {
        console.log(error);
        this.loadingData = false;
        this.infinShow = false;
        this.dataStage = "error";
      }
    },
    generateList(list) {
      const newArray = [];
      list.forEach((e) => {
        if (e.isMock) {
          newArray.push({
            month: e.month,
            year: e.year,
            data: [],
          });
        } else {
          newArray.push({
            month: e.month,
            year: e.year,
            data: [
              {
                title: "เป้าการสั่งซื้อต่อเดือนที่รับคะแนนพิเศษ",
                amount: e.targetReach,
                unit: "บาท",
              },
              {
                title: "ยอดการสั่งซื้อ",
                amount: e.currentTarget,
                unit: "บาท",
              },
              {
                title: "% เทียบเป้า",
                amount: e.percentage,
                unit: "%",
              },
              {
                title: "คะแนนที่ได้รับในการสั่งซื้อ",
                amount: e.receive,
                unit: "คะแนน",
              },
              {
                title: "คะแนนพิเศษที่ได้รับเมื่อผ่านเป้า",
                amount: e.special,
                unit: "คะแนน",
              },
              {
                title: "คะแนนรวม",
                amount: e.accumulateReceive,
                unit: "คะแนน",
              },
            ],
          });
        }
      });
      newArray.sort((a, b) => {
        if (a.year === b.year) {
          return a.month - b.month;
        } else {
          return a.year - b.year;
        }
      });
      return newArray;
    },
    async getPoint(shopCode) {
      try {
        const res = await fetch(
          `${process.env.VUE_APP_API_URL}/shop/${shopCode}/wholesalePoint`,
          {
            headers: {
              Authorization: `Bearer ${this.$JWT}`,
            },
          }
        );
        const { status } = res;
        switch (status) {
          case 200: {
            const body = await res.json();
            const { months, receive, used, remain, pending } = body;
            // console.log("point: ", body);
            this.total = receive;
            this.used = used;
            this.remain = remain;
            this.pending = pending;
            // const newArray = [];
            // if (months?.length > 0) {
            //   months.forEach((e) => {
            //     if (e.isMock) {
            //       newArray.push({
            //         month: e.month,
            //         year: e.year,
            //         data: [],
            //       });
            //     } else {
            //       newArray.push({
            //         month: e.month,
            //         year: e.year,
            //         data: [
            //           {
            //             title: "คะแนนที่ได้รับในการสั่งซื้อ",
            //             amount: e.receive,
            //           },
            //           {
            //             title: "คะแนนพิเศษที่ได้รับเมื่อผ่านเป้า",
            //             amount: e.special,
            //           },
            //           {
            //             title: "คะแนนรวม",
            //             amount: e.accumulateReceive,
            //           },
            //           // {
            //           //   title: "คะแนนที่ใช้ได้หลังผ่านเป้า 85%",
            //           //   list: [
            //           //     {
            //           //       title:
            //           //         "คะแนนที่ได้รับเดือน " +
            //           //         this.$getDate(e.month - 1),
            //           //       amount: e.receive,
            //           //     },
            //           //     {
            //           //       title: "คะแนนที่ใช้ได้สะสม",
            //           //       amount: e.accumulateReceive,
            //           //     },
            //           //   ],
            //           // },
            //           // {
            //           //   title: "คะแนนที่รอผ่านเป้า 85%",
            //           //   list: [
            //           //     {
            //           //       title: "คะแนนในเดือนปัจจุบัน",
            //           //       amount: e.pending,
            //           //     },
            //           //     { title: "คะแนนสะสม", amount: e.accumulatePending },
            //           //   ],
            //           // },
            //         ],
            //       });
            //     }
            //   });
            //   newArray.sort((a, b) => {
            //     if (a.year === b.year) {
            //       return a.month - b.month;
            //     } else {
            //       return a.year - b.year;
            //     }
            //   });
            // }

            this.dataList = months;
            break;
          }
          default:
            throw "Something when wrong!";
        }
      } catch (e) {
        console.log(e);
      }
    },
    dateTolocal(val) {
      return new Date(val)
        .toLocaleString("en-GB", { timeZone: "UTC" })
        .split(",")[0];
    },
    confirmClick(date, id, ref) {
      if (!this.$readOnly) {
        this.$router.push({
          name: "confirmPoint",
          params: { transDate: date, invoiceID: id, refOrder: ref },
        });
      }
    },
    incompleteAlert() {
      if (!this.$readOnly) {
        this.Swal.fire(
          "รายการไม่สมบูรณ์",
          "ยังไม่สามารถคำนวณคะแนนได้ โปรดติดต่อเจ้าหน้าที่",
          "warning"
        );
      }
    },
  },
  async mounted() {
    window.loading(false);
    if (!this.disablePage) {
      this.getInvoice();
    }
  },
};
</script>

<style lang="scss" scoped>
$blue: #2a5ca7;
$gray: #ced3da;

.root {
  font-size: 13px;
}

.wholesale-calendar-wrapper {
  font-size: 16px;
  hr {
    border-color: $gray;
    border-width: 5px;
  }
  .score-container {
    background: $blue;
    color: #fff;
  }
  .remark-container {
    font-size: 11px;
  }
}

.des {
  padding-top: 8px;
  align-self: center;
}

.item {
  /* padding: 10px 10px; */
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  padding: 10px 10px;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
}
.box_status {
  position: relative;
  width: 100%;
  border-radius: 25%;
  font-size: 15px;
}

.box_status:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.box_status .box_text {
  margin-left: auto;
  color: #ffffff;
  position: absolute;
  width: 100%;
  height: 100%;
}

.box_text .text {
  text-align: center;
  position: relative;
  top: 50%;
  padding: 5px;
  transform: translateY(-50%);
}

.box_text .text > div {
  width: 100%;
  height: 100%;
}

.hilight {
  color: #fbc02d;
}

.uncollect {
  background: #189ad6;
  line-height: 28px;
}

.pending {
  background: #979797;
}

.collected {
  background: #b2d235;
}

.incomplete {
  background: #a24242;
}

@media screen and (max-width: 400px) {
  .root {
    font-size: 12px;
  }
  .box_status {
    font-size: 12px;
  }
}
</style>
